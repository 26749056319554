
import Vue from 'vue'
import AddLocationDialog from '../components/AddLocationDialog.vue'
import EditLocationDialog from '../components/EditLocationDialog.vue'
import http from '@/http-common'
import { AxiosError, AxiosResponse } from 'axios'

export default Vue.extend({
  components: {
    AddLocationDialog,
    EditLocationDialog
  },
  data: () => ({
    addLocationDialogVisible: false,
    editLocationDialogVisible: false,
    confirmDelete: false,
    locations: [],
    editLocationId: -1,
    editLocationName: ""
  }),
  methods: {
    openPDF () {
      let url = "https://" + window.location.hostname + "/api/qr/"
      window.open(url, '_blank');
    },
    showSnackbar (e: string) {
      this.$emit('showSnackbar', e)
    },
    deleteLocation (id: string) {
      http.delete('/adminlocation/', {
        data: { id: id }
      }).then(() => {
        this.fetchLocations()
        this.$emit('showSnackbar', "Locatie verwijderd.")
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    },
    fetchLocations () {
      http.get('/adminlocation/', {
      }).then((response: AxiosResponse) => {
        this.locations = response.data
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    },
    clipboard(uuid: string){
      let url = "https://" + window.location.hostname + "/team/locatie/" + uuid
      navigator.clipboard.writeText(url)
      this.$emit('showSnackbar', "URL gekopieërd naar klembord.")
    },
    showEditLocationDialogVisible(id: number, name: string){
      this.editLocationId = id
      this.editLocationName = name
      this.editLocationDialogVisible = true
    }
  },
  computed: {
    baseURL () {
        return window.location.hostname
    }
  },
  mounted() {
    this.fetchLocations()
  },
})
