
import Vue from 'vue'
import SignUpDialog from '../components/SignUpDialog.vue'
import LogInDialog from '../components/LogInDialog.vue'

export default Vue.extend({
  components: {
    SignUpDialog,
    LogInDialog
  },
  data: () => ({
    signUpDialogVisible: false,
    logInDialogVisible: false
  }),
  methods: {
    showSnackbar (e: string) {
      this.$emit('showSnackbar', e)
    },
    closeLogInDialog () {
      this.logInDialogVisible = false
    },
    closeSignUpDialog () {
      this.signUpDialogVisible = false
    }
  }
})
