import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: undefined,
      is_admin: undefined,
      name: undefined,
    },
  },
  getters: {
  },
  mutations: {
    updateUser (state, payload) {
      state.user = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
