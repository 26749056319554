import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import AdminView from '../views/AdminView.vue'
import AdminTeams from '../views/AdminTeamsView.vue'
import AdminScoreView from '../views/AdminScoreView.vue'
import AdminLocationsView from '../views/AdminLocationsView.vue'
import AdminCheckinsView from '../views/AdminCheckinsView.vue'
import AdminSettingsView from '../views/AdminSettingsView.vue'
import AdminMapView from '../views/AdminMapView.vue'

import TeamView from '../views/TeamView.vue'
import TeamAllLocationsView from '../views/TeamAllLocationsView.vue'
import TeamLocationView from '../views/TeamLocationView.vue'
import TeamScoreView from '../views/TeamScoreView.vue'
import TeamPhoneView from '../views/TeamPhoneView.vue'
import TeamInfoView from '../views/TeamInfoView.vue'

import App from '../App.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
      path: '/',
      name: 'root',
      component: App
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: { name: "adminteams" },
    component: AdminView,
    children: [
      {
        path: '/admin/teams',
        name: 'adminteams',
        component: AdminTeams,
      },
      {
        path: '/admin/score',
        name: 'adminscore',
        component: AdminScoreView,
      },
      {
        path: '/admin/locaties',
        name: 'adminlocations',
        component: AdminLocationsView,
      },
      {
        path: '/admin/checkins',
        name: 'admincheckins',
        component: AdminCheckinsView,
      },
      {
        path: '/admin/kaart',
        name: 'adminmap',
        component: AdminMapView,
      },
      {
        path: '/admin/instellingen',
        name: 'admininstellingen',
        component: AdminSettingsView,
      }
    ]
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView,
    redirect: { name: "teamalllocations" },
    children: [
      {
        path: '/team/locaties',
        name: 'teamalllocations',
        component: TeamAllLocationsView,
      },
      {
        path: '/team/locatie/:uuid',
        name: 'teamlocation',
        props: true,
        component: TeamLocationView,
      },
      {
        path: '/team/telefoon',
        name: 'teamtelefoon',
        component: TeamPhoneView,
      },
      {
        path: '/team/info',
        name: 'teaminfo',
        component: TeamInfoView,
      },
      {
        path: '/team/score',
        name: 'teamscore',
        component: TeamScoreView,
      },
    ]
  },
  {
    path: '*',
    redirect: { name: 'root' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
