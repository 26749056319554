import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#3C3836',
        secondary: '#504945',
        accent: '#928374',
        error: '#CC241D',
        warning: '#FABD2F',
        info: '#EBDBB2',
        success: '#B8BB26',
        white: '#FFFFFF',
        background: '#282828'
      },
    },
  },
});
