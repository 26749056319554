
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError, AxiosResponse } from 'axios'

export default Vue.extend({
  data: () => ({
    message: ""
  }),
  mounted() {
    http.post(`/checkin/${this.$route.params.uuid}`, {
    }).then((response: AxiosResponse) => {
      this.message = response.data
    }).catch((error: AxiosError) => {
      this.$emit('showSnackbar', error.response?.data)
    })
  },
})
