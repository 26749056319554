
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
import http from '@/http-common'

// https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
import { AxiosError, AxiosResponse } from 'axios'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data: () => ({
      markers: [],
      zoom: 16,
      center: latLng(0, 0),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: false,
      checkins: {}
  }),
  mounted() {
    http.get('/adminlocation/', {
    }).then((response: AxiosResponse) => {
      this.markers = response.data
      this.center = latLng(response.data[0].coordinates.split(","))
      http.get('/checkin/-1', {
      }).then((response: AxiosResponse) => {
        for (let i = 0; i < response.data.length; i++) {
          let c = response.data[i]
          if (c.location_id in this.checkins) {
            this.checkins[c.location_id].push(`${c.team_name}: ${c.timestamp}`)
          } else {
            this.checkins[c.location_id] = [`${c.team_name}: ${c.timestamp}`]
          }
        }
        this.showMap = true
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    }).catch((error: AxiosError) => {
      this.$emit('showSnackbar', error.response?.data)
    })
  },
  methods: {
    getCheckins(id: number) {
      return this.checkins[id]
    },
    makeMarker(coordinates: string) {
      let tuple = coordinates.split(",")
      return latLng([Number(tuple[0]), Number(tuple[1]), 0])
    }
  }
};
