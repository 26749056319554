
import Vue from 'vue'

export default Vue.extend({
  components: {
  },
  data: () => ({
      drawer: true,
      navigation: [
        { to: '/admin/teams', icon: 'groups' },
        { to: '/admin/score', icon: 'format_list_numbered' },
        { to: '/admin/locaties', icon: 'location_on' },
        { to: '/admin/kaart', icon: 'map' },
        { to: '/admin/checkins', icon: 'rss_feed' },
        { to: '/admin/instellingen', icon: 'settings' },
      ],
  }),
  methods: {
    showSnackbar (e: string) {
      this.$emit('showSnackbar', e)
    }
  },
  computed: {
    userName () {
      return this.$store.state.user?.name
    },
    userNameFirstLetter () {
      return this.$store.state.user?.name?.charAt(0).toUpperCase()
    }
  },
})
