
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError } from 'axios'

export default Vue.extend({
  props: {
    locationName: {
      default: "",
      type: String
    },
    locationId: {
      default: -1,
      type: Number
    },
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    name: '',
    coordinates: '',
    order: '',
    uuid: '',
    isBreak: false,
    updateBreak: false,
  }),
  computed: {
    breakText () {
        return this.isBreak ? "Pauzepost" : "Geen pauzepost"
    }
  },
  methods: {
    enter (e: KeyboardEvent) {
      if (e.type === 'keydown' && e.key === 'Enter') {
        this.editLocation()
      }
    },
    editLocation () {
      if (!this.updateBreak) {
        this.isBreak = null
      }
      http.put('/adminlocation/', {
        name: this.name,
        id: this.locationId,
        uuid: this.uuid,
        coordinates: this.coordinates,
        order: this.order,
        is_break: this.isBreak
      }).then(() => {
        this.$emit('showSnackbar', "Locatie bijgewerkt.")
        this.$emit('close')
        this.$emit('update')
        this.name = ""
        this.coordinates = ""
        this.uuid = ""
        this.order = ""
        this.isBreak = false
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    },
    closeDialog (e: KeyboardEvent) {
      if ((e.type === 'keydown' && e.key === 'Escape') || e.type === 'click') {
        this.$emit('close')
      }
    },
  },
})
