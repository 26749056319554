
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError } from 'axios'

export default Vue.extend({
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    name: '',
    coordinates: '',
    order: '',
    isBreak: false,
  }),
  computed: {
    breakText () {
        return this.isBreak ? "Pauzepost" : "Geen pauzepost"
    }
  },
  methods: {
    enter (e: KeyboardEvent) {
      if (e.type === 'keydown' && e.key === 'Enter') {
        this.addLocation()
      }
    },
    addLocation () {
      http.post('/adminlocation/', {
        name: this.name,
        coordinates: this.coordinates,
        order: this.order,
        is_break: this.isBreak
      }).then(() => {
        this.$emit('showSnackbar', "Locatie toegevoegd.")
        this.$emit('close')
        this.$emit('update')
        this.name = ""
        this.coordinates = ""
        this.order = ""
        this.isBreak = false
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    },
    closeDialog (e: KeyboardEvent) {
      if ((e.type === 'keydown' && e.key === 'Escape') || e.type === 'click') {
        this.$emit('close')
      }
    },
  },
})
