
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError, AxiosResponse } from 'axios'
import TeamEditDialog from '../components/TeamEditDialog.vue'
import SignUpDialog from '../components/SignUpDialog.vue'

export default Vue.extend({
  components: {
    TeamEditDialog,
    SignUpDialog
  },
  data: () => ({
    signUpDialogVisible: false,
    users: [],
    admins: [],
    teamEditDialogVisible: false,
    teamName: "",
    teamId: -1
  }),
  methods: {
    closeSignUpDialog () {
      this.signUpDialogVisible = false
      this.fetchUsers()
    },
    openTeamEditDialog (name: string, id: number) {
      this.teamName = name
      this.teamId = id
      this.teamEditDialogVisible = true
    },
    showSnackbar (e: string) {
      this.$emit('showSnackbar', e)
    },
    fetchUsers () {
      http.get('/user/-1', {
      }).then((response: AxiosResponse) => {
        this.users = response.data.users
        this.admins = response.data.admins
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    }
  },
  mounted() {
    this.fetchUsers()
  },
})
