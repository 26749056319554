
import Vue from 'vue'

export default Vue.extend({
  components: {
  },
  data: () => ({
    drawer: true,
    navigation: [
      { to: '/team/locaties', icon: 'location_on' },
      { to: '/team/score', icon: 'format_list_numbered' },
      { to: '/team/telefoon', icon: 'call' },
      { to: '/team/info', icon: 'info' },
    ],
  }),
  methods: {
    showSnackbar (e: string) {
      this.$emit('showSnackbar', e)
    },
  },
  computed: {
    userName () {
      return this.$store.state.user?.name
    },
    userNameFirstLetter () {
      return this.$store.state.user?.name?.charAt(0).toUpperCase()
    }
  },
})
