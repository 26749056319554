
import Vue from 'vue';
import AccountView from './views/AccountView.vue'

import http from '@/http-common'
import { AxiosResponse } from 'axios'

Vue.mixin({
  methods: {
    redirectLogin() {
      if (this.$store.state.user?.is_admin) {
        this.$router.push({name: 'adminteams'})
      } else {
        this.$router.push({name: 'teamalllocations'})
      }
    },
    logout(){
      http.post('/auth/logout')
      .then(() => {
        this.$router.push("/")
        this.$store.commit('updateUser', {
          id: undefined,
          is_admin: undefined,
          name: undefined,
        })
      })
    },
  },
})

export default Vue.extend({
  data: () => ({
    snackbar: false,
    snackbarText: '',
    drawer: undefined,
    notFetched: true
  }),
  components: {
    AccountView
  },
  methods: {
    showSnackbar (text: string) {
      // Delete snackbar first so that the timeout will be reset
      this.snackbar = false
      this.snackbar = true
      this.snackbarText = text
    },
  },
  mounted() {
    http.get('/auth/getuser', {
    }).then((response: AxiosResponse) => {
      this.$store.commit('updateUser', response.data)
      this.notFetched = false
      if (this.$route.path === "/") {
        this.redirectLogin()
      }
    }).catch(() => {
      this.notFetched = false
    })
  },
  computed: {
    userFetchFail () {
      return this.$store.state.user?.name === undefined ? true : false
    }
  },
});
