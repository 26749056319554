
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError } from 'axios'

export default Vue.extend({
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    teamId: {
        default: -1,
        type: Number
    },
    teamName: {
        default: "",
        type: String
    }
  },
  data: () => ({
    confirmVisible: false,
    valid: true,
    showPassword: false,
    nameRules: [
      (v: string) => v.length == 0 || v.length >= 3 || 'Teamnaam moet minstens drie karakters zijn.'
    ],
    name: '',
    password: '',
    isAdmin: false,
    updateAdmin: false,
  }),
  methods: {
    closeDialog (e: KeyboardEvent) {
      if ((e.type === 'keydown' && e.key === 'Escape') || e.type === 'click') {
        this.$emit('close')
        this.confirmVisible = false
      }
    },
    enter (e: KeyboardEvent) {
      if (e.type === 'keydown' && e.key === 'Enter') {
        this.validate()
      }
    },
    validate () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        if (!this.updateAdmin) {
          this.isAdmin = null
        }
        http.put(`/user/${this.teamId}`, {
          new_name: this.name,
          new_pw: this.password,
          is_admin: this.isAdmin
        })
        .then(() => {
          this.$emit('showSnackbar', "Bijgewerkt.")
          this.$emit('update')
          this.$emit('close')
          this.confirmVisible = false
        })
        .catch((error: AxiosError) => {
          this.$emit('showSnackbar', error.response?.data)
        })
      }
    },
    deleteUser () {
        http.delete(`/user/${this.teamId}`, {
        })
        .then(() => {
          this.$emit('showSnackbar', "Verwijderd.")
          this.$emit('update')
          this.$emit('close')
          this.confirmVisible = false
        })
        .catch((error: AxiosError) => {
          this.$emit('showSnackbar', error.response?.data)
        })
    }
  },
  computed: {
    passwordRules () {
      return [
        (v: string) => v.length == 0 || v.length >= 8 || 'Wachtwoord moet minstens 8 karakters zijn.'
      ]
    },
    isAdminText () {
      return this.isAdmin ? "Administrator" : "Geen administrator"
    }
  }
})
