
import Vue from 'vue'

export default Vue.extend({
  components: {
  },
  props: {
    extended: {
      type: Object,
    }
  }
})
