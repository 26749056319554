
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError, AxiosResponse } from 'axios'

export default Vue.extend({
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    valid: true,
    showPassword: false,
    name: '',
    nameRules: [
      (v: string) => !!v || 'Teamnaam is verplicht.',
      (v: string) => (v && v.length >= 3) || 'Teamnaam moet minstens drie karakters zijn.'
    ],
    password: '',
  }),
  methods: {
    closeDialog (e: KeyboardEvent) {
      if ((e.type === 'keydown' && e.key === 'Escape') || e.type === 'click') {
        this.$emit('close')
      }
    },
    enter (e: KeyboardEvent) {
      if (e.type === 'keydown' && e.key === 'Enter') {
        this.validate()
      }
    },
    validate () {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        http.post('/auth/login', {
          name: this.name,
          pw: this.password
        })
        .then((response: AxiosResponse) => {
          this.$store.commit('updateUser', response.data)
          this.$emit('showSnackbar', "Welkom!")
          this.$emit('close')
          this.redirectLogin()
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status === 401) {
            this.$emit('showSnackbar', 'Combinatie teamnaam en wachtwoord is incorrect.')
          } else {
            this.$emit('showSnackbar', error.response?.data)
          }
        })
      }
    }
  },
  computed: {
    passwordRules () {
      return [
        (v: string) => !!v || 'Wachtwoord is verplicht.',
        (v: string) => v.length >= 8 || 'Wachtwoord moet minstens 8 karakters zijn.'
      ]
    }
  }
})
