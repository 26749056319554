
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError, AxiosResponse } from 'axios'

export default Vue.extend({
  data: () => ({
    showDetailedScoreBoard: [],
    allowSignup: []
  }),
  methods: {
    updateSettings() {
      http.post('/settings/', {
        show_detailed_scoreboard: this.showDetailedScoreBoard,
        allow_signup: this.allowSignup
      }).then(() => {
        this.$emit('showSnackbar', "Instellingen bijgewerkt.")
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    },
    fetchSettings() {
      http.get('/settings/', {
      }).then((response: AxiosResponse) => {
        this.showDetailedScoreBoard = response.data?.show_detailed_scoreboard
        this.allowSignup = response.data?.allow_signup
      }).catch((error: AxiosError) => {
        this.$emit('showSnackbar', error.response?.data)
      })
    },
  },
  mounted() {
    this.fetchSettings()
  },
})
