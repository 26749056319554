
import Vue from 'vue'
import http from '@/http-common'
import { AxiosError, AxiosResponse } from 'axios'

export default Vue.extend({
  data: () => ({
    checkins: []
  }),
  mounted() {
    http.get('/checkin/-1', {
    }).then((response: AxiosResponse) => {
      this.checkins = response.data
    }).catch((error: AxiosError) => {
      this.$emit('showSnackbar', error.response?.data)
    })
  },
})
